export default function useSpecialLayoutHandlingForProduct() {
  const specialLayouts = ["Pizza", "Hamburger"];
  const onlyPizza = ["Pizza", "Premium Chicago", "Hamburger"];
  const twoGrid = ["Deals"];
  const route = useRoute();
  const screenWidth = ref();
  const { width } = useWindowSize();

  const isPizzaOnly = computed(() => {
    return route.query.product ? onlyPizza.includes(route.query.product as string) : true;
  });
  const isBelongsToSpecial = computed(() => {
    return route.query.product ? specialLayouts.includes(route.query.product as string) : true;
  });
  const orderRow = computed(() => {
    return isBelongsToSpecial.value && screenWidth.value < 500;
  });
  const isFullSearchScreen = computed(() => {
    return screenWidth.value < 500;
  });
  const ifPizzaTwoGridCol = computed(() => {
    return route.query.product ? twoGrid.includes(route.query.product as string) : false;
  });
  const ifPizzaDealSmallScreen = computed(() => {
    return screenWidth.value < 500 && ifPizzaTwoGridCol.value;
  });
  watch(
    () => width.value,
    () => {
      screenWidth.value = width.value;
    },
  );
  onMounted(() => {
    screenWidth.value = width.value;
  });
  return {
    isBelongsToSpecial,
    orderRow,
    isPizzaOnly,
    isFullSearchScreen,
    ifPizzaTwoGridCol,
    ifPizzaDealSmallScreen,
  };
}
